
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "Finance",
    components: {
        ContentTitle,
    },
})
export default class Finance extends Vue {
    private contentTitle: string = "财务首页";
    private baseUrlIfram = process.env.VUE_APP_URL;
    private goWithdraw() {
        this.$router.push({ path: "/sale/withdraw"}).catch((err) => err);
    }
}
