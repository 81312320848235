
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "Invoice",
    components: {
        ContentTitle,
    },
})
export default class Invoice extends Vue {
    private contentTitle: string = "开票列表";
    private baseUrlIfram = process.env.VUE_APP_URL;
}
